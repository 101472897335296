<template>
  <!-- <div id="box">
    <router-view v-slot="{ Component }">
      <keep-alive :include="['addneworder', 'quoteorder']">
        <component :is="Component"></component>
      </keep-alive>
    </router-view>
  </div> -->
  <router-view>
  </router-view>
</template>
<script>
export default {}
</script>

<style lang="less" scoped>
#box {
  height: 100%;
  box-sizing: border-box;
}
</style>
